<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome do convênio</th>
          <th>Órgão conveniado</th>
          <th>Número de faixas</th>
          <th>Ativo</th>          
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="conveniosCotas in conveniosCotasFormat">
          <template>
            <tr :key="conveniosCotas.id">
              <td data-label="Nome:" class="topo-card">
                <span>{{ conveniosCotas.nome }}</span>
              </td>
              <td data-label="Órgão Conveniado:">
                <span>{{ conveniosCotas.nome_orgao_conveniado }}</span>
              </td>
              <td data-label="Número de faixas:">
                <span>{{ conveniosCotas.total_resultados_faixas }}</span>
              </td>
              <td data-label="Ativo:">
                <div v-if="conveniosCotas.ativo == true">
                  <span>Sim</span>
                </div>
                <div v-else>
                  <span>Não</span>
                </div>
              </td>              
              <td class='coluna-acoes'>
                <div class="coluna-acoes-tabela">
                  <!-- Visualizar -->
                  <div class="icone-tabela">
                    <router-link :to="{
                      name: 'ConveniosCotasShow',
                      params: { id: conveniosCotas.id },
                    }">
                      <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                    </router-link>
                  </div>
                  <!-- Editar -->
                  <div class="icone-tabela">
                    <router-link :to="{
                      name: 'ConveniosCotasEdit',
                      params: { id: conveniosCotas.id },
                    }">
                      <img src="../../assets/imagens/icon-edit.svg" alt="Editar" title="Editar" />
                    </router-link>
                  </div>
                  <!-- Excluir -->
                  <span class="icone-tabela" type="button" @click="opcoesExcluir(conveniosCotas.id)">
                    <img src="../../assets/imagens/icon-delete-preto.svg" alt="Excluir" title="Excluir" />
                  </span>
                </div>
              </td>
              <div class="modal-exclusao" :id="conveniosCotas.id">
                <div class="modal-mask">
                  <div class="modal-container">
                    <div class="modal-header">
                      <h2>Deseja mesmo excluir esse Convênio?</h2>
                    </div>
                    <div class="modal-body">
                      <p>Dados do Convênio:</p>
                      <div class="dados">
                        <div class="texto-centralizado">
                          Nome: <span>{{ conveniosCotas.nome }}</span>
                        </div>
                        <div class="texto-centralizado">
                          Órgão Conveniado: <span>{{ conveniosCotas.nome_orgao_conveniado }}</span>
                        </div>
                        <div class="texto-centralizado">
                          Número de faixas: <span>{{ conveniosCotas.total_resultados_faixas }}</span>
                        </div>
                        <div class="texto-centralizado">
                          <div v-if="conveniosCotas.ativo == true">
                            Ativo: <span>Sim</span>
                          </div>
                          <div v-else>
                            Ativo: <span>Não</span>
                          </div>
                        </div>                        
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button class="btn-confirmar-exclusao" @click="excluirLinha(conveniosCotas.id)">Sim</button>
                      <button class="btn-cancelar-exclusao" @click="opcoesExcluir(conveniosCotas.id)">Não</button>
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          </template>
        </template>

        <tr v-if="!conveniosCotas || conveniosCotas == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"

  import MsgErroFormularios from "../MsgErroFormularios";
  import ConvenioCotaApi from "../../services/ConvenioCotaApi"

  export default {
    name: "TabelaResultadosConvenios",
    props: {
      conveniosCotas: Array,
      convenioCota: Object,
    },
    created() {
      this.convenioCotaApi = new ConvenioCotaApi();
    },
    components: {
      MsgErroFormularios,
    },
    data() {
      return {
        msgErros: {
          convenio: '',
          default: '',
        },
        erro: false,
        conveniosCotasFormat: this.conveniosCotas.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      opcoesExcluir(convenioCotaId) {
        let modalExclusao = document.getElementsByClassName('modal-exclusao')

        for (let i = 0; i < modalExclusao.length; i++) {
          if (modalExclusao[i].id == convenioCotaId) {
            if (modalExclusao[i].style.visibility !== "visible") {
              modalExclusao[i].style.visibility = "visible"
            } else {
              modalExclusao[i].style.visibility = "hidden"
            }
          }
        }
      },
      async excluirLinha(convenioCotaId) {
        this.opcoesExcluir(convenioCotaId)

        try {
          this.carregando = true;
          this.erro = false;

          if (this.convenioCotaApi.cancelTokens.destroyConveniosCotas) {
            this.convenioCotaApi.cancelTokens.destroyConveniosCotas.cancel();
          }

          await this.convenioCotaApi.destroyConveniosCotas(convenioCotaId);
          window.location.reload();

        } catch (err) {
          if (this.convenioCotaApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;

          this.msgErros.convenio = "Não foi possível excluir o convênio/cota, pois ele está vinculado a uma cessão/disposição.";
        }
      },
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>