<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>CPF</th>
          <th>Servidor(a)</th>
          <th>Status</th>
          <th>Órgão de origem</th>
          <th>Órgão de destino</th>
          <th>Data de início da cessão</th>
          <th>Data de término da cessão</th>
          <!--<th>Data de entrada do processo</th>-->
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cessao in cessoesFormat" :key="cessao.id" :class="{ 'linha-selecionada': cessaoSelecionada && cessaoSelecionada.id === cessao.id }">
          <td data-label="CPF:" class="topo-card">
            <span>{{ formatarCPF(cessao.cpf_servidor) }}</span>
          </td>
          <td data-label="Nome do(a) servidor(a):" class="topo-card">
            <span>{{ cessao.nome_servidor }}</span>
          </td>
          <td for="selectStatus" data-label="Status:">
            <v-select
              class="vselect" 
              v-model="cessao.status_id" 
              :options="status"
              :reduce="status => status.id" 
              label="nome"
              :clearable="false"
              @input="abrirPopUpConfirmacao(cessao)">
            </v-select>
          </td>
          <td data-label="Órgão de origem:">
            <span>{{ truncateText(cessao.orgao_origem, 30) }}</span>
          </td>
          <td data-label="Órgão de destino:">
            <span>{{ truncateText(cessao.orgao_destino, 30) }}</span>
          </td>
          <td data-label="Data de inicio da cessão:">
            <span>{{ formatarData(cessao.data_inicio) }}</span>
          </td>
          <td data-label="Data de término da cessão:">
            <span v-if="cessao.data_termino !== null">{{ formatarData(cessao.data_termino) }}</span>
            <span v-else>Indeterminada</span>
          </td>
          
          <!--
          <td data-label="Data de entrada do processo:">
            <span>{{ formatarData(cessao.data_entrada_processo) }}</span>
          </td>-->
          <td class='coluna-acoes'>
            <div class="coluna-acoes-tabela">
              <!-- Visualizar -->
              <div class="icone-tabela">
                <router-link :to="{
                  name: 'CessaoShow',
                  params: { id: cessao.id, renovar: false },
                }">
                  <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                </router-link>
              </div>
              <!-- Editar -->
              <div class="icone-tabela">
                <router-link :to="{
                  name: 'CessaoEdit',
                  params: { id: cessao.id, renovar: false },
                }">
                  <img src="../../assets/imagens/icon-edit.svg" alt="Editar" title="Editar" />
                </router-link>
              </div>
              <!-- Renovar cessão -->
              <div class="icone-tabela">
                <router-link :to="{
                  name: 'CessaoEdit',
                  params: { id: cessao.id, renovar: true },
                }">
                  <img src="../../assets/imagens/icon-renew.png" alt="Renovar cessão" title="Renovar cessão" />
                </router-link>
              </div>
              <!-- Excluir -->
              <span class="icone-tabela" type="button" @click="opcoesExcluir(cessao.id)">
                <img src="../../assets/imagens/icon-delete-preto.svg" alt="Excluir" title="Excluir" />
              </span>
            </div>
          </td>
          <div class="modal-exclusao" :id="cessao.id">
            <div class="modal-mask">
              <div class="modal-container">
                <div class="modal-header">
                  <h2>Deseja mesmo excluir a cessão?</h2>
                </div>
                <div class="modal-body">
                  <p>Dados da cessão:</p>
                  <div class="dados">
                    <div class="texto-centralizado">
                      Nome do(a) servidor(a): <span>{{ cessao.nome_servidor }}</span>
                    </div>
                    <div class="texto-centralizado">
                      CPF: <span>{{ formatarCPF(cessao.cpf_servidor) }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Órgão de origem: <span>{{ cessao.orgao_origem }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Órgão de destino: <span>{{ cessao.orgao_destino }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Data de início da cessão: <span>{{ formatarData(cessao.data_inicio) }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Data de término da cessão: <span>{{ formatarData(cessao.data_termino) }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Ônus: <span>{{ cessao.onus }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Status: <span>{{ cessao.status }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Número do processo no SEI: <span>{{ cessao.numero_processo_sei }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Nome do convênio: <span>{{ cessao.nome_convenio }}</span>
                    </div>
                    <div class="texto-centralizado">
                      <div v-if="cessao.fc == true">
                        FC: <span>Sim</span>
                      </div>
                      <div v-else>
                        Cargo: <span>Sim</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn-confirmar-exclusao" @click="$emit('excluir-linha', cessao.id)">Sim</button>
                  <button class="btn-cancelar-exclusao" @click="opcoesExcluir(cessao.id)">Não</button>
                </div>
              </div>
            </div>
          </div>
        </tr>
        <tr v-if="!cessoesFormat || cessoesFormat.length === 0" class="sem-resultados">
          <td colspan="7"><b>Nenhum Resultado Encontrado</b></td>
        </tr>
      </tbody>
    </table>
    <div v-if="mostrarPopUpConfirmacao" class="modal-edit">
      <div class="modal-mask">
        <div class="modal-container">
          <div class="modal-header">
            <h2>Deseja mesmo editar o status dessa cessão?</h2>
          </div>
          <div class="modal-body">
            <p>Dados da Cessão:</p>
            <div class="dados">
              <div class="texto-centralizado">
                Nome do(a) servidor(a): <span>{{ nomeServidorSelecionado }}</span>
              </div>
              <div class="texto-centralizado">
                CPF: <span>{{ formatarCPF(cpfSelecionado) }}</span>
              </div>
              <div class="texto-centralizado">
                Novo Status: <span>{{ statusNovoSelecionado }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn-confirmar-edicao" @click="confirmarAtualizacao">Sim</button>
            <button class="btn-cancelar-edicao" @click="cancelarAtualizacao">Não</button>
          </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns";
  import vSelect from "vue-select";
  import CessaoApi from "../../services/CessaoApi";

  export default {
    name: "TabelaResultadosCessoes",
    props: {
      cessoes: Array,
      cessao: Object,
    },
    data() {
      return {
        cessoesFormat: this.cessoes.map((v) => {
          return {
            ...v,
          };
        }), 
      
        msgErros: {
          status: ''
        },
        mostrarPopUpConfirmacao: false,
        cpfSelecionado: '',
        nomeServidorSelecionado: '',
        statusSelecionado: '',
        novoStatus: null,
        cessaoSelecionada: {},
        status: [],
        cessoesData: [],
        
        TabelaResultadosCessoes: this.cessao
          ? {
              id: this.cessao.id,
              status_id: this.cessao.status_id,
          }
          : {
              id: null,
              status_id: null,
          },
          
      };
    },
    components: {
              vSelect,
              
    },
    created() {
      this.cessaoApi = new CessaoApi();
      this.carregarStatus();
      this.carregarCessoes();
    },
    methods: {
      async carregarCessoes() {
        try {
            if (this.carregandoFiltro && !this.erroFiltro) return;

            this.erroFiltro = false

            const dadosCessoesData = await this.cessaoApi.getCessoesData();
            this.cessoesData = dadosCessoesData
        } catch (err) {
            console.log("carregarCessoes", err);
            this.carregandoFiltro = false;
            this.erroFiltro = true;
            this.cessoesData = [];
        }
      },
      async abrirPopUpConfirmacao(cessao) {
        // Armazena os dados relevantes
        this.cessaoSelecionada = cessao;
        this.cpfSelecionado = this.formatarCPF(cessao.cpf_servidor);
        this.nomeServidorSelecionado = cessao.nome_servidor;
        this.statusNovoSelecionado = this.status.find(status => status.id === cessao.status_id).nome;
        this.novoStatus = cessao.status_id;
        this.mostrarPopUpConfirmacao = true;
      },
      opcoesExcluir(cessaoId) {
        let modalExclusao = document.getElementsByClassName('modal-exclusao')

        for (let i = 0; i < modalExclusao.length; i++) {
          if (modalExclusao[i].id == cessaoId) {
            if (modalExclusao[i].style.visibility !== "visible") {
              modalExclusao[i].style.visibility = "visible"
            } else {
              modalExclusao[i].style.visibility = "hidden"
            }
          }
        }
      },
      async carregarStatus() {
        try {
            if (this.carregandoFiltro && !this.erroFiltro) return;

            this.erroFiltro = false

            const dadosStatus = await this.cessaoApi.getStatus();
            this.status = dadosStatus
        } catch (err) {
            console.log("carregarStatus", err);
            this.carregandoFiltro = false;
            this.erroFiltro = true;
            this.status = [];
        }
      },
      async confirmarAtualizacao() { 
        this.cessaoSelecionada.status_id = this.novoStatus;
          this.validarEditarStatus();
            await this.cessaoApi.setCessao(
              this.cessaoSelecionada
            );
        this.mostrarPopUpConfirmacao = false;
        this.$emit('cancelar-popup');
      },
      validarEditarStatus() {
        //Validar cessão para checar se já tem outra existente com o mesmo servidor,
        //se tiver, pegar as cessões existentes desse servidor e alterar os status para encerrado.
        //Isso tudo só vai acontecer se o status da cessão atual (criada ou editada) for mudado ou colocado como vigente.
        
        let idStatusVigente;
        this.status.map((elemento) => {
            if(elemento.codigo == 2) {
                idStatusVigente = elemento.id
            }
        })

        if(this.cessaoSelecionada.status_id === idStatusVigente) {
            let idStatusEncerrado;
            this.status.map((elemento) => {
                if(elemento.codigo == 5) {
                    idStatusEncerrado = elemento.id
                }
            })
        this.cessoesData.map(async (cessaoExistente) => {
            if(cessaoExistente.servidor_id == this.cessaoSelecionada.servidor_id && 
                cessaoExistente.status_id == idStatusVigente &&
                this.cessaoSelecionada.id !== cessaoExistente.id) {
                cessaoExistente.status_id = idStatusEncerrado;
                await this.cessaoApi.setCessao(
                    cessaoExistente
                );
            }
        });
        }
    },
      cancelarAtualizacao() {
          // Reverter o status para o valor anterior
          this.mostrarPopUpConfirmacao = false;
          this.$emit('cancelar-popup');
      },
      truncateText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.slice(0, maxLength) + "...";
        }
      },
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
      formatarCPF(cpf) {
        // Lógica para formatar o CPF (exemplo: 123.456.789-01)
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .linha-selecionada {
    background-color: yellow;
  }

  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            
            .icone-tabela {
              margin: 0 .05rem 0 .05rem;
            }
          }
        }
      }

      td {
        img {
          height: 1.5rem;
          margin: 0rem 0.5rem 0rem 0.5rem;
        }
      }

      .modal-edit {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;

        .modal-mask {
          border-radius: 1rem;
          position: relative;
          z-index: 1000;
          top: 20vh;
          width: calc(40vw - 10rem);
          height: auto;
          background-color: #f2f2f2;
          transition: opacity 0.3s ease;
          margin: 0 auto;
        }

        .modal-container {
          height: 100%;
          padding: .7rem;
          position: relative;
          overflow-y: auto;
          overflow-x: auto;
          font-family: "Montserrat-Medium";
          align-content: center;

          .modal-header {
            height: fit-content;
            width: fit-content;
            margin: .5rem auto .3rem auto;

            h2 {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .modal-body {
            border: 1px solid gray;
            border-radius: 1rem;
            text-align: -webkit-center;

            p {
              font-size: 1rem;
              font-weight: bold;
              margin-top: .3rem;
              margin-bottom: .5rem;
              margin-left: 1rem;
              color: #005516;
            }

            .dados {
              font-weight: bold;
              font-size: 1rem;
              text-align-last: left;
              margin: 0 1rem .5rem 1rem;

              span {
                font-weight: normal;
                font-size: .9rem;
                margin-left: .3rem;
              }
            }
          }

          .modal-footer {
            margin: .7rem auto 0rem auto;
            place-content: space-evenly;
            font-size: 1rem;

            .btn-confirmar-edicao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #005516;
              color: #f2f2f2;
            }

            .btn-confirmar-edicao:hover {
              background-color: #005517c2;
            }

            .btn-cancelar-edicao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #bd0000;
              color: #f2f2f2;
            }

            .btn-cancelar-edicao:hover {
              background-color: #bd0000c4;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: "570px") and (max-width: "1065px") {
    .resultados {
      .modal-edit {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;

        .modal-mask {
          border-radius: 1rem;
          position: relative;
          z-index: 1000;
          top: 20vh;
          width: calc(40vw - 10rem);
          height: auto;
          background-color: #f2f2f2;
          transition: opacity 0.3s ease;
          margin: 0 auto;
        }

        .modal-container {
          height: 100%;
          padding: .7rem;
          position: relative;
          overflow-y: auto;
          overflow-x: auto;
          font-family: "Montserrat-Medium";
          align-content: center;

          .modal-header {
            height: fit-content;
            width: fit-content;
            margin: .5rem auto .3rem auto;

            h2 {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .modal-body {
            border: 1px solid gray;
            border-radius: 1rem;
            text-align: -webkit-center;

            p {
              font-size: 1rem;
              font-weight: bold;
              margin-top: .3rem;
              margin-bottom: .5rem;
              margin-left: 1rem;
              color: #005516;
            }

            .dados {
              font-weight: bold;
              font-size: 1rem;
              text-align-last: left;
              margin: 0 1rem .5rem 1rem;

              span {
                font-weight: normal;
                font-size: .9rem;
                margin-left: .3rem;
              }
            }
          }

          .modal-footer {
            margin: .7rem auto 0rem auto;
            place-content: space-evenly;
            font-size: 1rem;

            .btn-confirmar-edicao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #005516;
              color: #f2f2f2;
            }

            .btn-confirmar-edicao:hover {
              background-color: #005517c2;
            }

            .btn-cancelar-edicao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #bd0000;
              color: #f2f2f2;
            }

            .btn-cancelar-edicao:hover {
              background-color: #bd0000c4;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: "60px") and (max-width: "569px") {
    .resultados {
      .modal-edit {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;

        .modal-mask {
          border-radius: 1rem;
          position: relative;
          z-index: 1000;
          top: 20vh;
          width: calc(40vw - 10rem);
          height: auto;
          background-color: #f2f2f2;
          transition: opacity 0.3s ease;
          margin: 0 auto;
        }

        .modal-container {
          height: 100%;
          padding: .7rem;
          position: relative;
          overflow-y: auto;
          overflow-x: auto;
          font-family: "Montserrat-Medium";
          align-content: center;

          .modal-header {
            height: fit-content;
            width: fit-content;
            margin: .5rem auto .3rem auto;

            h2 {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .modal-body {
            border: 1px solid gray;
            border-radius: 1rem;
            text-align: -webkit-center;

            p {
              font-size: 1rem;
              font-weight: bold;
              margin-top: .3rem;
              margin-bottom: .5rem;
              margin-left: 1rem;
              color: #005516;
            }

            .dados {
              font-weight: bold;
              font-size: 1rem;
              text-align-last: left;
              margin: 0 1rem .5rem 1rem;

              span {
                font-weight: normal;
                font-size: .9rem;
                margin-left: .3rem;
              }
            }
          }

          .modal-footer {
            margin: .7rem auto 0rem auto;
            place-content: space-evenly;
            font-size: 1rem;

            .btn-confirmar-edicao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #005516;
              color: #f2f2f2;
            }

            .btn-confirmar-edicao:hover {
              background-color: #005517c2;
            }

            .btn-cancelar-edicao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #bd0000;
              color: #f2f2f2;
            }

            .btn-cancelar-edicao:hover {
              background-color: #bd0000c4;
            }
          }
        }
      }
    }
  }
</style>