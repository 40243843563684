import Cookies from 'js-cookie';
import AuthApi from "./AuthApi";

const get = require("lodash.get");

const USUARIO_KEY = "usuario_movimentacoes_servidores";
const AUTH_PUBLIC_COOKIE = 'movimentacao_auth_public';
//const AUTH_PUBLIC_COOKIE = 'auth_public';

class Auth {
    constructor() {
        this.authApi = new AuthApi();
    }

    async login(dados) {
        try {
            const { usuario } = await this.authApi.autenticar(dados);
            localStorage.setItem(USUARIO_KEY, JSON.stringify(usuario));
        } catch (err) {
            console.log(err);
            const mensagem = get(err, 'response.data.mensagem', 'Houve um erro ao fazer login. Por favor tente novamente')
            throw mensagem;
        }
    }

    logout() {
        try {
            localStorage.removeItem(USUARIO_KEY);
            Cookies.remove(AUTH_PUBLIC_COOKIE)
            window.close();
        } catch (err) {
            console.log(err);
            const mensagem = get(err, 'response.data.mensagem', 'Houve um erro ao fazer logout. Por favor tente novamente')
            throw mensagem;
        }
    }

    loggedIn() {
        return !!this.usuario && Cookies.get(AUTH_PUBLIC_COOKIE);
    }

    get usuario() {
        const usuarioString = localStorage.getItem(USUARIO_KEY);
        if (usuarioString) {
            return JSON.parse(usuarioString);
        }
        return null;
    }

    get temPermissao() {
        /*  ADMINS QUE POSSUEM PERMISSÃO TOTAL (POSSUEM ACESSO A TUDO MENOS CRIAÇÃO E EDIÇÃO): 
                - USUÁRIOS INTERNOS DA CASA CIVIL (1), LOTADOS NA GPD (5), SLAT (10), GAB (12) e CGAB (13);
                - USUÁRIOS EXTERNOS DA SEAD (2), TCE-GO (82).
            
            USUARIOS INTERNOS DA CASA CIVIL, LOTADOS EM OUTRAS ÁREAS, E USUÁRIOS EXTERNOS, VISUALIZAM APENAS DE SEU ÓRGÃO. 
        */
        const usuarioString = JSON.parse(localStorage.getItem(USUARIO_KEY));
        let temPermissao = { admin: false, gpd: false };

        if (usuarioString && usuarioString.funcionalidades) {
            const { funcionalidades, orgao } = usuarioString;
            const { id: orgaoId, setor } = orgao;
            const setorId = setor?.id;

            if (funcionalidades.includes("cessoes_new_edit_delete")) {
                temPermissao.admin = true;
            } else if (
                (orgaoId === 1 && (setorId === 5 || setorId === 10 || setorId === 12 || setorId === 13)) || orgaoId === 2 || orgaoId === 82) {
                temPermissao.gpd = true;
            }
        }
        return temPermissao;
    }
}

export default Auth;