<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Código</th>
          <th>Ativo</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="status in statusFormat">
          <template>
            <tr :key="status.id">
              <td data-label="Nome:">
                <span>{{ status.nome }}</span>
              </td>
              <td data-label="Código:">
                <span>{{ status.codigo }}</span>
              </td>
              <td data-label="Status ativo:">
                <span v-if="status.ativo == true">Sim</span>
                <span v-else>Não</span>
              </td>
              <td class='coluna-acoes'>
                <div class="coluna-acoes-tabela">
                  <!-- Visualizar -->
                  <div class="icone-tabela">
                    <router-link :to="{
                      name: 'StatusShow',
                      params: { id: status.id },
                    }">
                      <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>

        <tr v-if="!statuses || statuses == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"
  import MsgErroFormularios from "../MsgErroFormularios";
  import StatusApi from "../../services/StatusApi";

  export default {
    name: "TabelaResultadosStatus",
    props: {
      statuses: Array,
      status: Object,
    },
    components: {
      MsgErroFormularios,
    },
    created() {
      this.statusApi = new StatusApi();
    },
    data() {
      return {
        msgErros: {
          status: '',
        },
        erro: false,
        statusFormat: this.statuses.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      }
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            
            text-align-last: center;

            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .05rem 0 .05rem;
            }
          }
        }
      }
    }
  }
</style>