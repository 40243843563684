<template>
  <div class="resultados">
    <h2>
      <b>menos de {{ dias }} dias restantes 
        <span v-if="cessoesFormat.length > 1 || cessoesFormat == 0">( Total: {{ cessoesFormat.length }} cessões )</span>
        <span v-else>( Total: {{ cessoesFormat.length }} cessão )</span>
      </b>
    </h2>
    <table>
      <thead>
        <tr>
          <th>CPF</th>          
          <th>Nome</th>
          <th>Data de início da cessão</th>
          <th>Data de término da cessão</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="cessao in cessoesFormat">
          <template>
            <tr :key="cessao.id">
              <td data-label="CPF do(a) servidor(a):">
                <span>{{ formatarCPF(cessao.cpf_servidor) }}</span>
              </td>              
              <td data-label="Nome do(a) servidor(a):">
                <span>{{ cessao.nome_servidor }}</span>
              </td>
              <td data-label="Data de início da cessão:">
                <span>{{ formatarData(cessao.data_inicio) }}</span>
              </td>
              <td data-label="Data de término da cessão:">
                <span v-if="cessao.data_termino !== null">{{ formatarData(cessao.data_termino) }}</span>
                <span v-else>Indeterminada</span>
              </td>
              <td class='coluna-acoes'>
                <div class="coluna-acoes-tabela">
                  <!-- Renovar cessão -->
                  <div v-if="temPermissao.admin || temPermissao.gpd" class="icone-tabela">
                    <router-link :to="{
                      name: 'CessaoEdit',
                      params: { id: cessao.id, renovar: true },
                    }">
                      <img src="../../assets/imagens/icon-renew.png" alt="Renovar cessão" title="Renovar cessão" />
                    </router-link>
                  </div>
                  <!-- Visualizar -->
                  <div class="icone-tabela">
                    <router-link :to="{
                      name: 'CessaoShow',
                      params: { id: cessao.id, renovar: false },
                    }">
                      <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                    </router-link>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>

        <tr v-if="!cessoes || cessoes == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"

  export default {
    name: "TabelaResultadosCessoes",
    props: {
      cessoes: Array,
      cessao: Object,
      dias: Number,
      temPermissao: Object,
    },
    data() {
      return {
        cessoesFormat: this.cessoes.map((cessao) => {
          return {
            ...cessao,
          };
        }),
      };
    },
    methods: {
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarCPF(cpf) {
        if(cpf) {
          // Lógica para formatar o CPF (exemplo: 123.456.789-01)
          return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
          return null
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      h2 {
        font-size: .95rem;
        color: #bd0000;
        text-align: center;
        margin: 0rem 0 1rem 0;
        padding-top: 1rem;

        b {
          span {
            margin-left: .5rem;
            color: #484344;
          }
        }
      }

      table {
        tbody {
          tr {
            td {
              padding: 1rem .5rem .5rem 1rem;
              vertical-align: middle;
              font-size: .9rem;
            }

            td:nth-child(1) {
              text-align: center;
              min-width: 4rem;
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: center;
            }

            td:nth-child(4) {
              text-align: center;
            }

            td:nth-child(5) {
              text-align: center;
              max-width: 4rem;
            }
          }

          .sem-resultados {
            height: fit-content;
            background-color: transparent !important;
            text-align: center;
            color: rgb(73, 73, 73);
            font-style: oblique;
          }
        }
      }
    }
  }

  @media screen and (min-width: "570px") and (max-width: "1065px") {
    .resultados {
      h2 {
        font-size: .95rem;
        color: #bd0000;
        text-align: center;
        margin: 0rem 0 1rem 0;
        padding-top: 1rem;
      }
    }
  }

  @media screen and (min-width: "60px") and (max-width: "569px") {
    .resultados {
      h2 {
        font-size: .95rem;
        color: #bd0000;
        text-align: center;
        margin: 0rem 0 1rem 0;
        padding-top: 1rem;
      }
    }
  }
</style>