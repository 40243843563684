<template>
    <div>
        <!--<div class="botao">
            <button @click="embedReport">Abrir relatório</button>
        </div>-->
        <div class="reportMessage">Relatório indisponível para essa resolução de tela!</div>
        <div id="reportContainer"></div>
    </div>
</template>

<script src="resources/js/powerbi.min.js" type="text/javascript"></script>
<script>
    import * as pbi from "powerbi-client";

    import PainelBIApi from "../../services/PainelBIApi";
    import Auth from '../../services/Auth';
    import metaDados from "../../helpers/metaDados";

    export default {
        name: "PainelBI",
        metaInfo() {
            return {
                title: "Painel BI",
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                {
                    vmid: "description",
                    name: "description",
                    property: "description",
                    content:
                    "Painel BI - Casa Civil do Estado de Goiás.",
                },
                {
                    vmid: "og:description",
                    property: "og:description",
                    content:
                    "Painel BI - Casa Civil do Estado de Goiás.",
                },
                {
                    vmid: "og:title",
                    property: "og:title",
                    content: "Painel BI - Casa Civil do Estado de Goiás",
                },
                ...metaDados(this.$route),
                ],
            };
        },
        data() {
        return {
            usuario: null,            
            usuarioOrgaoId: null,
            usuarioUnidadeAutorizada: null,
            }
        },        
        mounted() {
            this.initPowerBI();
        },
        created() {
            this.painelBIApi = new PainelBIApi();
            let auth = new Auth();
            this.usuario = auth.usuario;     
            this.retornarOrgaoIdUsuario();   
            this.retornaSetorUsuarioLogadoAutorizado();
        },
        methods: {
            async retornaSetorUsuarioLogadoAutorizado() {
                try {
                this.carregando = true;
                this.erro = false;

                if (this.painelBIApi.cancelTokens.getSetorUsuarioLogadoAutorizado) {
                    this.painelBIApi.cancelTokens.getSetorUsuarioLogadoAutorizado.cancel();
                }

                const dadosUsuarioUnidade = await this.painelBIApi.getSetorUsuarioLogadoAutorizado(this.usuario.id);
                this.usuarioUnidadeAutorizada = dadosUsuarioUnidade;

                } catch (err) {
                if (this.painelBIApi.isCancel(err)) {
                    return;
                }

                this.carregando = false;
                this.erro = true;
                this.usuarioOrgaoId = null;
                }
            },
            async retornarOrgaoIdUsuario() {
                try {
                this.carregando = true;
                this.erro = false;

                if (this.painelBIApi.cancelTokens.getOrgaoIdUsuarioLogado) {
                    this.painelBIApi.cancelTokens.getOrgaoIdUsuarioLogado.cancel();
                }

                const dadosUsuarioOrgao = await this.painelBIApi.getOrgaoIdUsuarioLogado(this.usuario.orgao.id);
                this.usuarioOrgaoId = dadosUsuarioOrgao[0]

                } catch (err) {
                if (this.painelBIApi.isCancel(err)) {
                    return;
                }

                this.carregando = false;
                this.erro = true;
                this.usuarioOrgaoId = null;
                }
            },
            async initPowerBI() {
                let embedDataTokens = await this.obterToken();
                let embedDataReport = await this.obterEmbedUrl();
                let embedUrl = embedDataReport.embedUrl;
                let reportId = embedDataReport.id;

                try {                   
                    var orgaoID = "";
                    var paginaQueQuero = "";

                    if(this.usuarioOrgaoId.id == 11424 && this.usuarioUnidadeAutorizada){ // Casa Civil e unidades autorizadas: 'GPDOA', 'SLAT', 'CGAB', 'GAB'
                        orgaoID = ";";
                        paginaQueQuero = "ReportSection";
                    }else if(this.usuarioOrgaoId.id == 11455 || this.usuarioOrgaoId.id == 11422){ // TCE, SEAD
                        orgaoID = ";";
                        paginaQueQuero = "fbac1a1feb6ecee09a92";
                    }else{
                        orgaoID = ";" + this.usuarioOrgaoId.id + ";";
                        paginaQueQuero = "fbac1a1feb6ecee09a92";
                    }

                    const advancedFilter = {
                    $schema: "http://powerbi.com/product/schema#advanced",
                    target: {
                        table: "fato_cessao",
                        column: "OrgaoOrigemOuDestino"
                    },
                    logicalOperator: "And",
                    conditions: [
                        {
                        operator: "Contains",
                        value: orgaoID
                        }
                    ]
                    };

                    let reportContainer = document.getElementById('reportContainer');
                    powerbi.bootstrap(reportContainer, {type: "report"})

                    let reportLoadConfig = { 
                        type: "report",
                        tokenType: pbi.models.TokenType.Embed,
                        accessToken: embedDataTokens.token, 
                        embedUrl: embedUrl,
                        filters: [advancedFilter],
                        pageName: paginaQueQuero,  
                        settings: {
                            background: pbi.models.BackgroundType.Transparent,
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                },
                                pageNavigation: {
                                    visible: false
                                },
                            },
                        }
                    };

                    let tokenExpiry = embedDataTokens["tokenExpiry"]
                    
                    let report = powerbi.embed(reportContainer, reportLoadConfig);

                    // Clear any other loaded handler events
                    report.off("loaded");

                    // Triggers when a content schema is successfully loaded
                    report.on("loaded", function () {
                        console.log("Report load successful");
                    });

                    // Clear any other rendered handler events
                    report.off("rendered");

                    // Triggers when a content is successfully embedded in UI
                    report.on("rendered", function () {
                        console.log("Report render successful");
                    });

                    // Clear any other error handler event
                    report.off("error");

                    // Below patch of code is for handling errors that occur during embedding
                    report.on("error", function (event) {
                        const errorMsg = event.detail;

                        // Use errorMsg variable to log error in any destination of choice
                        console.error(errorMsg);
                    });
                } catch (error) {
                    console.error("Error embedding report:", error);
                }
            },
            async obterToken() {
                try {
                    const response = await this.painelBIApi.obterToken();
                    const token = await response; // Aguarde o token ser resolvido

                    if (token) {
                        return token;
                    } else {
                        throw new Error('Empty access token');
                    }
                } catch (error) {
                    console.error('Failed to get access token:', error);
                }
            },
            async obterEmbedUrl() {
                try {
                    const response = await this.painelBIApi.obterEmbedUrl();
                    const token = await response; // Aguarde o token ser resolvido

                    if (token) {
                        return token;
                    } else {
                        throw new Error('Empty access token');
                    }
                } catch (error) {
                    console.error('Failed to get access token:', error);
                }
            },
            /*async embedReport() {
                // Chamar a função de inicialização do Power BI para incorporar o relatório
                this.initPowerBI();
            },*/
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (min-width: 1000px){
        #reportContainer {
            margin: .8rem auto 0 auto;
            background: #fff;
            color: #6E6E6E;
            font: Lighter 40px Segoe UI;    
            height: calc(0.58 * 75vw); /* 16:9 aspect ratio */
            min-width: 800px;
            text-align: center;
            width: 100%;
        }
        .reportMessage {
            display: none;
        }
    }
    @media screen and (max-width: 999px){
        #reportContainer {
            display: none;
        }
        .reportMessage {
            display: block;
            color: red;
            font-size: 1.5rem;
            margin: 50vh 5rem;
            text-align: center;
        }
    }    
    .botao {
        button {
            border-radius: .5rem;
            border: none;
            background-color: #125b19;
            padding: .5rem 1rem;
            margin: 2rem 0 1rem 3rem;
            font-size: 1.2rem;
            color: #fff;
        }

        button:hover {
            color: #ffa600;
            box-shadow: #139b2b 1px 2px 10px;
            text-decoration: underline;
        }
    }
</style>