<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>CPF</th>
          <th>Servidor(a)</th>
          <th>Status</th>
          <th>Órgão de origem</th>
          <th>Órgão de destino</th>
          <th>Data de início da cessão</th>
          <th>Data de término da cessão</th>
          <!--<th>Data de entrada do processo</th>-->
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cessao in cessoesFormat" :key="cessao.id">
          <td data-label="CPF:" class="topo-card">
            <span>{{ formatarCPF(cessao.cpf_servidor) }}</span>
          </td>
          <td data-label="Nome do(a) servidor(a):" class="topo-card">
            <span>{{ cessao.nome_servidor }}</span>
          </td>
          <td for="selectStatus" data-label="Status:">
            <v-select
              class="vselect" 
              v-model="cessao.status_id" 
              :options="status"
              :reduce="status => status.id" 
              label="nome"
              :clearable="false"
              @input="abrirPopUpConfirmacao(cessao)"
              disabled>
            </v-select>
          </td>
          <td data-label="Órgão de origem:">
            <span>{{ truncateText(cessao.orgao_origem, 30) }}</span>
          </td>
          <td data-label="Órgão de destino:">
            <span>{{ truncateText(cessao.orgao_destino, 30) }}</span>
          </td>
          <td data-label="Data de inicio da cessão:">
            <span>{{ formatarData(cessao.data_inicio) }}</span>
          </td>
          <td data-label="Data de término da cessão:">
            <span v-if="cessao.data_termino !== null">{{ formatarData(cessao.data_termino) }}</span>
            <span v-else>Indeterminada</span>
          </td>
          
          <!--
          <td data-label="Data de entrada do processo:">
            <span>{{ formatarData(cessao.data_entrada_processo) }}</span>
          </td>-->
          <td class='coluna-acoes'>
            <div class="coluna-acoes-tabela">
              <!-- Visualizar -->
              <div class="icone-tabela">
                <router-link :to="{
                  name: 'CessaoShow',
                  params: { id: cessao.id, renovar: false },
                }">
                  <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                </router-link>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="!cessoesFormat || cessoesFormat.length === 0" class="sem-resultados">
          <td colspan="7"><b>Nenhum Resultado Encontrado</b></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns";
  import vSelect from "vue-select";
  import CessaoApi from "../../services/CessaoApi";

  export default {
    name: "TabelaResultadosCessoes",
    props: {
      cessoes: Array,
      cessao: Object,
    },
    data() {
      return {
        cessoesFormat: this.cessoes.map((v) => {
          return {
            ...v,
          };
        }), 
        msgErros: {
          status: ''
        },
        cessaoSelecionada: {},
        status: [],
        cessoesData: [],
        TabelaResultadosCessoes: this.cessao
          ? {
            id: this.cessao.id,
            status_id: this.cessao.status_id,
          }
          : {
            id: null,
            status_id: null,
          },
      };
    },
    components: {
      vSelect,      
    },
    created() {
      this.cessaoApi = new CessaoApi();
      this.carregarStatus();
      this.carregarCessoes();
    },
    methods: {
      async carregarCessoes() {
        try {
            if (this.carregandoFiltro && !this.erroFiltro) return;

            this.erroFiltro = false

            const dadosCessoesData = await this.cessaoApi.getCessoesData();
            this.cessoesData = dadosCessoesData
        } catch (err) {
            console.log("carregarCessoes", err);
            this.carregandoFiltro = false;
            this.erroFiltro = true;
            this.cessoesData = [];
        }
      },
      async carregarStatus() {
        try {
            if (this.carregandoFiltro && !this.erroFiltro) return;

            this.erroFiltro = false
            const dadosStatus = await this.cessaoApi.getStatus();
                      this.status = dadosStatus
        } catch (err) {
            console.log("carregarStatus", err);
            this.carregandoFiltro = false;
            this.erroFiltro = true;
            this.status = [];
        }
      },
      truncateText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.slice(0, maxLength) + "...";
        }
      },
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
      formatarCPF(cpf) {
        // Lógica para formatar o CPF (exemplo: 123.456.789-01)
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            
            text-align-last: center;

            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .05rem 0 .05rem;
            }
          }
        }
      }
    }
  }
</style>