<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome do convênio</th>
          <th>Órgão conveniado</th>
          <th>Número de faixas</th>
          <th>Ativo</th>          
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="conveniosCotas in conveniosCotasFormat" :key="conveniosCotas.id">
          <td data-label="Nome:" class="topo-card">
            <span>{{ conveniosCotas.nome }}</span>
          </td>
          <td data-label="Órgão Conveniado:">
            <span>{{ conveniosCotas.nome_orgao_conveniado }}</span>
          </td>
          <td data-label="Número de faixas:">
            <span>{{ conveniosCotas.total_resultados_faixas }}</span>
          </td>
          <td data-label="Ativo:">
            <span v-if="conveniosCotas.ativo == true">Sim</span>
            <span v-else>Não</span>
          </td>              
          <td class='coluna-acoes'>
            <div class="coluna-acoes-tabela">
              <!-- Visualizar -->
              <div class="icone-tabela">
                <router-link :to="{
                  name: 'ConveniosCotasShow',
                  params: { id: conveniosCotas.id },
                }">
                  <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                </router-link>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="!conveniosCotas || conveniosCotas == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"

  import MsgErroFormularios from "../MsgErroFormularios";
  import ConvenioCotaApi from "../../services/ConvenioCotaApi"

  export default {
    name: "TabelaResultadosConvenios",
    props: {
      conveniosCotas: Array,
      convenioCota: Object,
    },
    created() {
      this.convenioCotaApi = new ConvenioCotaApi();
    },
    components: {
      MsgErroFormularios,
    },
    data() {
      return {
        msgErros: {
          convenio: '',
          default: '',
        },
        erro: false,
        conveniosCotasFormat: this.conveniosCotas.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .resultados {
      table {
        tbody {
          tr {
            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          .coluna-acoes-tabela {
            
            text-align-last: center;

            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .05rem 0 .05rem;
            }
          }
        }
      }
    }
  }
</style>