<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>CPF</th>
          <th>Nome</th>
          <th>Quantidade de cargos</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="servidor in servidoresFormat" :key="servidor.id">
          <td data-label="CPF:" class="topo-card">
            <span>{{ formatarCPF(servidor.cpf) }}</span>
          </td>
          <td data-label="Nome:">
            <span>{{ servidor.nome }}</span>
          </td>
          <td data-label="Quantidade de cargos:">
            <span>{{ servidor.quantidade_cargos }}</span>
          </td>
          <td class='coluna-acoes'>
            <div class="coluna-acoes-tabela">
              <!-- Visualizar -->
              <div class="icone-tabela">
                <router-link :to="{
                  name: 'ServidorShow',
                  params: { id: servidor.id },
                }">
                  <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                </router-link>
              </div>
              <!-- Editar -->
              <div class="icone-tabela">
                <router-link :to="{
                  name: 'ServidorEdit',
                  params: { id: servidor.id },
                }">
                  <img src="../../assets/imagens/icon-edit.svg" alt="Editar" title="Editar" />
                </router-link>
              </div>
              <!-- Excluir -->
              <span class="icone-tabela" type="button" @click="opcoesExcluir(servidor.id)">
                <img src="../../assets/imagens/icon-delete-preto.svg" alt="Excluir" title="Excluir" />
              </span>
            </div>
          </td>
          <div class="modal-exclusao" :id="servidor.id">
            <div class="modal-mask">
              <div class="modal-container">
                <div class="modal-header">
                  <h2>Deseja mesmo excluir esse(a) Servidor(a)?</h2>
                </div>
                <div class="modal-body">
                  <p>Dados do(a) Servidor(a):</p>
                  <div class="dados">
                    <div class="texto-centralizado">
                      CPF: <span>{{ formatarCPF(servidor.cpf) }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Nome: <span>{{ servidor.nome }}</span>
                    </div>
                    <div class="texto-centralizado">
                      Quantidade de cargos: <span>{{ servidor.quantidade_cargos }}</span>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button class="btn-confirmar-exclusao" @click="excluirLinha(servidor.id)">Sim</button>
                  <button class="btn-cancelar-exclusao" @click="opcoesExcluir(servidor.id)">Não</button>
                </div>
              </div>
            </div>
          </div>
        </tr>
        <tr v-if="!servidores || servidores == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"
  import MsgErroFormularios from "../MsgErroFormularios";
  import ServidorApi from "../../services/ServidorApi";
  import CargoServidorApi from "../../services/CargoServidorApi";

  export default {
    name: "TabelaResultadosServidores",
    props: {
      servidores: Array,
      servidor: Object,
    },
    components: {
      MsgErroFormularios,
    },
    created() {
      this.servidorApi = new ServidorApi();
      this.cargoServidorApi = new CargoServidorApi();
    },
    data() {
      return {
        cargosServidores: [],
        msgErros: {
          servidor: '',
        },
        erro: false,
        servidoresFormat: this.servidores.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      async carregarCargosServidores(idServidor) {
        try {
            this.carregando = true;
            this.erro = false;

            if(idServidor !== null) {
              const dadosCargosServidores = await this.cargoServidorApi.getCargosServidor(idServidor);
              this.cargosServidores = dadosCargosServidores
            }

        } catch (err) {
            console.log("carregarCargosServidores", err);

            this.carregando = false;
            this.erro = true;
        }
      },
      opcoesExcluir(servidorId) {
        let modalExclusao = document.getElementsByClassName('modal-exclusao')

        for (let i = 0; i < modalExclusao.length; i++) {
          if (modalExclusao[i].id == servidorId) {
            if (modalExclusao[i].style.visibility !== "visible") {
              modalExclusao[i].style.visibility = "visible"
            } else {
              modalExclusao[i].style.visibility = "hidden"
            }
          }
        }
      },
      async excluirLinha(servidorId) {
        this.opcoesExcluir(servidorId)

        await this.carregarCargosServidores(servidorId);

        try {
          this.carregando = true;
          this.erro = false;

          if (this.servidorApi.cancelTokens.destroyServidor) {
            this.servidorApi.cancelTokens.destroyServidor.cancel();
          }

          for (const cargoServidor of this.cargosServidores) {
            await this.cargoServidorApi.destroyCargosServidores(cargoServidor.cargo_id, cargoServidor.servidor_id);
          }

          await this.servidorApi.destroyServidor(servidorId);
          window.location.reload();
          
        } catch (err) {
          if (this.servidorApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;

          this.msgErros.servidor = "Não foi possível excluir o(a) servidor(a), pois ele(a) está vinculado(a) a uma cessão/disposição."
        }
      },
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      },
      formatarCPF(cpf) {
        // Remove qualquer caractere que não seja número
        cpf = cpf.replace(/\D/g, '');

        // Aplica a máscara
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

        return cpf;
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>