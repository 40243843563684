import axios from "axios";
import axiosInstance from "./axiosInstance";

class PainelBIApi {
  cancelTokens = {
    getAccessToken: null,
    gerarIds: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getAccessToken() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAccessToken = source;

    try {
      const { data } = await axiosInstance.get(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/paineis_bi/getAccessToken`,
        {
          cancelToken: source.token,
        }
      );

      this.cancelTokens.getAccessToken = null;
      return data;
    } catch (error) {
      if (error.response) {
        // Handle errors from the API
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else {
        // Handle other errors
        console.error(error.message);
      }

      throw error;
    }
  }

  async gerarIds() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.gerarIds = source;

    try {
      const { data } = await axiosInstance.get(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/paineis_bi/gerarIds`,
        {
          cancelToken: source.token,
        }
      );

      this.cancelTokens.gerarIds = null;
      return data;
    } catch (error) {
      if (error.response) {
        // Handle errors from the API
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else {
        // Handle other errors
        console.error(error.message);
      }

      throw error;
    }
  }

  async obterToken() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.obterToken = source;

    const resultado = await this.gerarIds();

    // URL da API
    const url =
      "https://api.powerbi.com/v1.0/myorg/groups/" +
      (resultado.group_id) +
      "/reports/" +
      (resultado.report_id) +
      "/GenerateToken";

    // Token de autorização
    const authToken = "Bearer " + (await this.getAccessToken());

    // Corpo da requisição
    const requestBody = {
      accessLevel: "View",
      allowSaveAs: "false",
    };

    try {
      // Fazendo a requisição POST
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: authToken,
        },
      });

      return response.data; // Retorna os dados do token gerado
    } catch (error) {
      throw new Error("Erro ao gerar o token: " + error.message);
    }
  }

  async obterEmbedUrl() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.obterEmbedUrl = source;

    const resultado = await this.gerarIds();

    // URL da API
    const url =
      "https://api.powerbi.com/v1.0/myorg/groups/" +
      (resultado.group_id) +
      "/reports/" +
      (resultado.report_id);

    // Token de autorização
    const authToken = "Bearer " + (await this.getAccessToken());

    try {
      // Fazendo a requisição GET
      const response = await axios.get(url, {
        headers: {
          Authorization: authToken,
        },
      });

      return response.data; // Retorna os dados da url gerada
    } catch (error) {
      throw new Error("Erro ao gerar o token: " + error.message);
    }
  }  

  async getOrgaoIdUsuarioLogado(usuarioLogadoOrgaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    this.cancelTokens.getOrgaoIdUsuarioLogado = source;
  
    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/paineis_bi/getOrgaoIdUsuarioLogado`,
      {
        cancelToken: source.token,
        params: {
          usuarioLogadoOrgaoId: usuarioLogadoOrgaoId,
        },
      }
    );
  
    this.cancelTokens.getOrgaoIdUsuarioLogado = null;
  
    return data;
  }

  async getSetorUsuarioLogadoAutorizado(usuario_logado_id) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    this.cancelTokens.getSetorUsuarioLogadoAutorizado = source;
  
    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/paineis_bi/getSetorUsuarioLogadoAutorizado`,
      {
        cancelToken: source.token,
        params: {
          usuario_logado_id: usuario_logado_id,
        },
      }
    );
  
    this.cancelTokens.getSetorUsuarioLogadoAutorizado = null;
  
    return data;
  }  
}

export default PainelBIApi;
